<template>
  <div id="receipt">
    <!-- @click="clickDownload" -->
    <div class="title">
      <span>回执单</span>
    </div>
    <div class="djdate">到货日期：</div>
    <div class="signature">
      <span class="danw">收货单位：<span v-html="this.invoice_title"></span></span>
    </div>
    <table class="table-box" width="100%" cellspacing="0">
      <!-- <tr height="30" style="border-bottom: 1px solid #000">
        <th align="left" class="right-box">
          <div class="margin-box"></div>
        </th>
        <th colspan="2" align="center" class="left-box"></th>
      </tr> -->
      <tr height="30">
        <th class="fixed-left">编号</th>
        <th class="title-box">产品名称</th>
        <th class="fixed-right">到货数量</th>
      </tr>
      <tr height="30" v-for="(item, index) in tableList" :key="index">
        <td align="center" style="border-right: 0; border-left: none">
          {{ 1 + index }}
        </td>
        <td align="center" style="border-right: 0">
          {{ item.goods_title }}（{{ item.sku_key }}）
        </td>
        <td align="center" style="border-right: none">{{ item.nums }}</td>
      </tr>
      <tr>
        <td style="border-left: none; border-right: none" colspan="3">
          <div class="remark">
            <span>到货情况说明：</span><br />
            <span style="color: #ccc">（包装是否完好、品质是否满意等）</span>
          </div>
        </td>
      </tr>
      <tr height="30">
        <td style="border-left: none; border-right: none" colspan="3">
          <div class="margin-box">收货人签章：</div>
        </td>
      </tr>
      <tr height="30" style="border-bottom: 1px solid #000">
        <td style="border-left: none; border-right: none" colspan="3">
          <div class="margin-box">联系方式：</div>
        </td>
      </tr>
    </table>
    <div class="margin-box" style="margin: 10px 0;">
      注：到货确认无误后填报，<span style="color: #F30000; font-weight: 600">根据财务最新要求，回执单请使用扫描件格式。</span>
    </div>
  </div>
</template>

<script>
import html2canvas from "html2canvas";
import { GetReceiptList, AliStsToken } from "@/api/common";
import qs from "qs";
import OSS from "ali-oss";

export default {
  name:'Receipt',
  data() {
    return {
      //最多十条 接口返回后处理
      tableList: [],
      uploadConf: {
        region: "oss-cn-beijing",
        accessKeyId: null,
        accessKeySecret: null,
        bucket: "21dunew",
        stsToken: null,
      },
      //   图片路径
      successUrl: "",
      token: "",
      orderCode: "",
      invoice_title:''
    };
  },
  mounted() {
    this.token = this.$route.query.token;
    this.orderCode = this.$route.query.orderCode;
    //测试
    // console.log(this.token)
    this.getToken();
    this.getTableList();
    window.iosFn = this.iosCallJs;
    window.andoridFn = this.androidCallJs;
  },
  methods: {
    getTableList() {
      let params = {
        order_code: this.orderCode,
        token: this.token,
      };
      GetReceiptList(qs.stringify(params)).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // 根据total 截取10条数据
          //重新赋值
          this.tableList = res.data.goods_list;
          this.invoice_title = res.data.invoice_title
        }
      });
    },
    clickDownload() {
      let dom = document.querySelector("#receipt");
      let options = {};
      html2canvas(dom, options).then((canvas) => {
        //   console.log(canvas)
        //   return
        let dataURL = canvas.toDataURL("image/jpeg");
        // document.body.appendChild(canvas);
        // this.fileDownload(dataURL);
        // console.log(dataURL)
        // console.log(this.base64toFile(dataURL))
        const fileName = `${Date.parse(new Date())}` + ".png"; //定义唯一的文件名
        new OSS(this.uploadConf)
          .put(fileName, this.base64toFile(dataURL), {
            ContentType: "image/jpeg",
          })
          .then((res) => {
            console.log(res);
            this.successUrl = res.name;
            var u = navigator.userAgent;
            var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if (isAndroid) {
              // android
              window.AndroidWebView.showInfoFromJs(this.successUrl);
            }
            if (isiOS) {
              // IOS
              window.webkit.messageHandlers.showMessage.postMessage(
                this.successUrl
              );
            }
          })
          .catch((err) => {
            console.log(`阿里云OSS上传图片失败回调`, err);
            this.$toast("阿里云OSS上传图片失败回调");
          });
      });
    },
    fileDownload(downloadUrl) {
      let aCreate = document.createElement("a"); //创建a元素
      aCreate.style.display = "none"; //隐藏a元素
      aCreate.href = downloadUrl; //把dataURL给a元素的href属性
      aCreate.download = "回执单.png";
      document.body.appendChild(aCreate); //触发点击
      aCreate.click();
      document.body.removeChild(aCreate); //移除
    },
    getToken() {
      AliStsToken(qs.stringify({})).then((res) => {
        this.uploadConf.accessKeyId = res.data.AccessKeyId;
        this.uploadConf.accessKeySecret = res.data.AccessKeySecret;
        this.uploadConf.stsToken = res.data.SecurityToken;
      });
    },

    base64toFile(dataurl, filename = "file") {
      let arr = dataurl.split(",");

      let mime = arr[0].match(/:(.*?);/)[1];

      let suffix = mime.split("/")[1];

      let bstr = atob(arr[1]);

      let n = bstr.length;

      let u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], `${filename}.${suffix}`, {
        type: mime,
      });
    },
    iosCallJs() {
      this.clickDownload();
    },
    androidCallJs() {
      this.clickDownload();
    },
  },
};
</script>

<style lang="scss" scoped>
#receipt {
  padding: 10px;

  table,
  td {
    // border: 1px solid #000;
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    border-bottom: none;
  }
  .table-box {
    border-collapse: collapse;
    background-color: #fff;
  }
  .title {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    text-align: center;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    font-weight: 700;
  }

  .djdate{
    text-align: right;
    margin-right: 50px;
    margin-bottom: 5px;
  }
  .right-box {
    border-right: 0;
    width: 100px;
  }
  .left-box {
    border-left: 0;
  }
  .fixed-left {
    width: 30px;
  }
  .title-box {
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #000;
  }
  .fixed-right {
    width: 80px;
    border-left-style: solid;
    border-left-width: 1px;
    border-left-color: #000;
  }
  .remark {
    height: 180px;
    margin: 10px;
  }
  .margin-box {
    margin: 10px;
  }
  .signature {
    border-style: solid;
    border-width: 1px;
    border-color: #000;
    border-bottom: none;
    color: #000;
    //   padding-left: 5px;
    line-height: 20px;
    .danw,
    .djdate {
      width: 50%;
      padding-left: 5px;
    }
  }
}
</style>